require("jquery")
require("jquery-validation")
$(function() {
  $('input[type="radio"]').bind('change', function (v) {
    var id = $(v.target).attr("id");
    var label = $('label[for='+  id  +']');
    label.parent().find("label").removeClass("text-danger border border-danger rounded");
    label.addClass( "text-danger border border-danger rounded" );
    $(v.target).parent('td').removeClass("error-shadow");
  });

  $('.text-area-action').bind('change', function (v) {
    var id = $(v.target).attr("id");
    $(v.target).removeClass("error-shadow");
    $(v.target).parent().find('label[for='+  id  +']').remove();
  });

  num_page = parseInt($("#num_page").html());

  $(".clear-btn").click(function() {
    var current_tab = parseInt($("#current_tab").html());
    $('#pq-' + current_tab).find("input[type=text], textarea, select").val("");
    $('#pq-' + current_tab).find("input[type=radio]").prop('checked', false);
    $.each($('#pq-' + current_tab).find('.priority-radio'), function( index, value ) {
      var id = $(value).attr("id");
      var label = $('#pq-' + current_tab).find('label[for='+  id  +']');
      label.parent().find("label").removeClass("text-danger border border-danger rounded");
    });
    $.each($('#pq-' + current_tab).find('input[type=radio]:checked'), function( index, value ) {
      var id = $(value).attr("id");
      var label = $('#pq-' + current_tab).find('label[for='+  id  +']');
      label.addClass( "text-danger border border-danger rounded" );
      $(value).parent('td').removeClass("error-shadow");
    });
    $.each($('#pq-' + current_tab).find('td'), function( index, value ) {
      $(value).removeClass("error-shadow");
    });
    $.each($('#pq-' + current_tab).find('textarea'), function( index, value ) {
      $(value).removeClass("error-shadow");
    });
    $.each($('#pq-' + current_tab).find('.select-ordinal'), function( index, value ) {
      handleSelectbox(value);
    });
    // window.scrollTo(0, 0);
    $('html, body').animate({ scrollTop: 0 }, 'fast');
  });

  $('.reset-btn').click(function () {
    $('#survey_form')[0].reset();
    clearAll();
    // window.scrollTo(0, 0);
    $('html, body').animate({ scrollTop: 0 }, 'fast');

    if($("#stt_survey").html() == "new"){
      $("#current_tab").html(1)
      $('.question_tab').hide();
      $('#pq-1').show();
      $('.strategies_tab').hide();
      $('#strategy-1').show();
//      $('.back-btn').hide();
      if(num_page > 1){
        $('.next-btn').show();
        $('.sub-btn').hide();
      }
    }
    handleSelectbox(this)
  });

  $('select').on('change', function (v) {
    handleSelectbox(v.target);
  });

  $('input[type=radio]').click(function(){
    if (this.checked) {
      var id = $(this).attr("id");
      var label = $('label[for='+  id  +']');
      if ($(label).hasClass("text-danger border border-danger rounded")) {
        this.checked = false;
        $(label).removeClass("text-danger border border-danger rounded");
      }
    }
  });
  
  var option_rules = {};
  for (var n = 0; n < num_page; ++ n) {
    var number_of_policies = parseInt($("#number_of_policies_" + (n+1)).html());
    ['ordinal_number', 'priority_compare_next', 'priority_compare_next_2', 'priority_compare_next_3'].forEach(function(attr){
      for (let i = 0; i < number_of_policies; i++) {
        option_rules["user[questions_attributes][" + (n*3) + "][options_attributes][" + i + "][" + attr + "]"] = "required"
        option_rules["user[questions_attributes][" + (n*3 + 1) + "][options_attributes][" + i + "][" + attr + "]"] = "required"
      }
    });
    option_rules["user[questions_attributes][" + (n*3 + 2) + "][content]"] = { maxlength: 1000 };
  }

  $(".edit_user").validate({
    onkeyup: false,
    onfocusout: false,
    onclick: false,
    invalidHandler: function(event, validator) {
      var errors = validator.numberOfInvalids();
      if (errors) {
        var message = '未入力があります。全ての項目への入力が必要です。'
        $("div.flash-form").html(message);
        $("div.flash-form").show().delay(5000).fadeOut();
      } else {
        $("div.flash-form").hide();
      }
    },
    errorPlacement: function (error, element) {
      if ( element.is("textarea") ) {
        element.addClass("error-shadow");
        error.insertBefore(element);
      } else {
        element.parent('td').addClass("error-shadow");
      }
    },
    rules: option_rules,
    messages: {
      "user[questions_attributes][2][content]": {
        maxlength: "1000文字まで入力できます",
        required: ""
      }
    }
  });

  // $('.next-btn').on('click touchstart', function(event){
  $('.next-btn').click(function(){
    var current_tab = parseInt($("#current_tab").html()) + 1;

    if(current_tab <= num_page){
      var result = $(".edit_user").valid();
      if(result){
        if(current_tab == 1){
          $('body').css('background','#f0f8ff');
        }else if(current_tab == 2){
          $('body').css('background','#fffff0');
        }else if(current_tab == 3){
          $('body').css('background','#f7f9fc');
        }else{
          $('body').css('background','');
        }

        // window.scrollTo(0, 0);
        $('html, body').animate({ scrollTop: 0 }, 'fast');
        $("#current_tab").html(current_tab)
        $('.question_tab').hide();
        $('#pq-' + (current_tab)).show();
        $('.strategies_tab').hide();
        $('#strategy-' + (current_tab)).show();
        $('.back-btn').show();
        if (current_tab <= (num_page - 1)){
          $('.next-btn').show();
          $('.sub-btn').hide();
        } else {
          $('.next-btn').hide();
          $('.sub-btn').show();
        }
      }
    }
  });

  $('.back-btn').click(function(){
    var current_tab = parseInt($("#current_tab").html()) - 1;

    if(current_tab >= 0){
      if(current_tab == 1){
        $('body').css('background','#f0f8ff');
      }else if(current_tab == 2){
        $('body').css('background','#fffff0');
      }else if(current_tab == 3){
        $('body').css('background','#f7f9fc');
      }else{
        $('body').css('background','');
      }

      // window.scrollTo(0, 0);
      $('html, body').animate({ scrollTop: 0 }, 'fast');
      $("#current_tab").html(current_tab)
      $('.question_tab').hide();
      $('#pq-' + (current_tab)).show();
      $('.strategies_tab').hide();
      $('#strategy-' + (current_tab)).show();
      $('.next-btn').show();
      $('.back-btn').show();
    }

    if (current_tab == num_page){
      $('.sub-btn').show();
    } else {
      $('.sub-btn').hide();
    }
  });
});

function clearAll() {
  $.each(document.getElementsByClassName('priority-radio'), function( index, value ) {
    var id = $(value).attr("id");
    var label = $('label[for='+  id  +']');
    label.parent().find("label").removeClass("text-danger border border-danger rounded");
  });
  $.each($('input[type=radio]:checked'), function( index, value ) {
    var id = $(value).attr("id");
    var label = $('label[for='+  id  +']');
    label.addClass( "text-danger border border-danger rounded" );
    $(value).parent('td').removeClass("error-shadow");
  });
  $.each($('td'), function( index, value ) {
    $(value).removeClass("error-shadow");
  });
  $.each($('textarea'), function( index, value ) {
    $(value).removeClass("error-shadow");
  });
}

function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

function handleSelectbox(target) {
  var is_mobile = $("#is_mobile").html() === 'true';
  var $targetSelect = $(target);

  // targetのname属性からインデックスを取得
  var nameAttr = $targetSelect.attr('name');
  var match = nameAttr.match(/\[questions_attributes\]\[(\d+)\]/);
  var groupIndex = match ? match[1] : null;

  if (!groupIndex) return; // グループが識別できなければ終了

  // 同じグループインデックスを持つselect要素を取得
  var list;
  if (is_mobile) {
    var parentTd = $targetSelect.closest('td');
    var tdId = parentTd.attr('id');
    list = $('td#' + tdId + ' select[name^="user[questions_attributes][' + groupIndex + ']"]');
  } else {
    list = $targetSelect.closest('table').find('select[name^="user[questions_attributes][' + groupIndex + ']"]');
  }

  // グループ内の選択されている値を収集
  var selectedValues = [];
  list.each(function() {
    var selectedValue = $(this).val();
    if (selectedValue !== '') {
      selectedValues.push(selectedValue);
    }
  });

  // グループ内の各ドロップダウンのオプションを表示/非表示に設定
  list.each(function() {
    if (isIOS()) {
      // iOSのみ: disabled属性を使用
      $(this).find("option").each(function() {
        $(this).prop("disabled", false);
      });
      var $currentSelect = $(this);
      selectedValues.forEach(function(value) {
        if ($currentSelect.val() !== value) {
          $currentSelect.find("option[value='" + value + "']").prop("disabled", true);
        }
      });
    } else {
      // 他のブラウザ: 通常の表示/非表示を使用
      $(this).find("option").show();
      var $currentSelect = $(this);
      selectedValues.forEach(function(value) {
        if ($currentSelect.val() !== value) {
          $currentSelect.find("option[value='" + value + "']").hide();
        }
      });
    }
  });

  // エラー表示を削除
  $targetSelect.closest('td').removeClass("error-shadow");
}

$(document).ready(function() {
  // 各selectにchangeイベントをバインド
  $('select').on('change', function(e) {
    handleSelectbox(e.target);
  });

  // ページ読み込み時に初期化
  $(".select-ordinal").each(function() {
    handleSelectbox(this);
  });
});
